import { createMuiTheme } from "@material-ui/core/styles"

const orange = "#E24C2F"
const white = "#FFFFFF"

export default createMuiTheme({
  palette: {
    primary: {
      main: `${orange}`,
    },
    secondary: {
      main: `${white}`,
    },
  },
  text: {
    primary: "#333",
    lightBlue: "#0077b5",
    orange: `${orange}`,
  },
  typography: {
    fontFamily: "Roboto",
    color: "red",
    fontSize: 12,
    text: {
      color: "red",
    },
    tab: {
      fontWeight: "normal",
      fontSize: "1rem",
      color: "#333",
    },
    tabButton: {
      fontWeight: "normal",
      fontSize: "1rem",
      color: `${orange}`,
    },
  },
})
